import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-18b26872"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "caodi"
};
const _hoisted_2 = {
  key: 0,
  class: "top_img"
};
const _hoisted_3 = {
  class: "top_img2"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "chuan"
};
const _hoisted_6 = {
  class: "cshu"
};
const _hoisted_7 = {
  class: "cnub"
};
const _hoisted_8 = {
  class: "cdes"
};
const _hoisted_9 = {
  class: "cname"
};
const _hoisted_10 = {
  class: "cmsg"
};
const _hoisted_11 = {
  class: "price"
};
const _hoisted_12 = {
  class: "price1"
};
const _hoisted_13 = {
  class: "price1"
};
const _hoisted_14 = {
  class: "tab_ship"
};
const _hoisted_15 = {
  class: "tab_ship1"
};
const _hoisted_16 = ["onClick"];
const _hoisted_17 = ["src"];
const _hoisted_18 = {
  class: "com_btn"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_icon = _resolveComponent("van-icon");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
    title: $data.lang.u1,
    onClickLeft: $options.back,
    onClickRight: $options.onClickLeft
  }, null, 8, ["title", "onClickLeft", "onClickRight"]), $data.buy.logo ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", {
    class: "top_img1",
    style: _normalizeStyle('background-color:' + $data.buy.color)
  }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
    src: $data.app_root + $data.buy.logo
  }, null, 8, _hoisted_4)])], 4)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString($data.buy.id < 10 ? "0" + $data.buy.id.toString() : $data.buy.id), 1), _createElementVNode("div", {
    class: "ctag",
    style: _normalizeStyle('color:' + $data.buy.color)
  }, _toDisplayString($data.buy.shu), 5)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString($data.buy.title), 1), _createElementVNode("div", _hoisted_10, _toDisplayString($data.buy.des), 1), _createElementVNode("div", _hoisted_11, [_createElementVNode("span", null, "Price ：" + _toDisplayString($data.buy.price) + "USDT", 1), _createElementVNode("span", null, "Push ： " + _toDisplayString($data.buy.day) + "Times", 1)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("span", null, _toDisplayString($data.lang.t1) + "：" + _toDisplayString($data.buy.max) + " ships", 1)]), _createElementVNode("div", _hoisted_13, [_createElementVNode("span", null, [_createTextVNode(_toDisplayString($data.lang.t5) + "：" + _toDisplayString($data.lang.t5) + " : " + _toDisplayString($data.buy.chan) + " HOC ", 1), _createVNode(_component_van_icon, {
    name: "question-o",
    onClick: _cache[0] || (_cache[0] = $event => $options.qust())
  })])])])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.chuan, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "tab_li",
      onClick: $event => $options.tab_ship(item),
      style: _normalizeStyle(item.id == $data.buy.id ? 'border-color:red;border-width:0rem' : '')
    }, [_createElementVNode("img", {
      src: $data.app_root + item.logo,
      style: _normalizeStyle(item.id == $data.buy.id ? 'border-color:red;border-width:0rem' : 'border: 0.1rem solid ' + item.color)
    }, null, 12, _hoisted_17)], 12, _hoisted_16);
  }), 256))])]), _createElementVNode("div", {
    class: _normalizeClass($data.buy.status == 1 ? 'com_btn' : 'com_btn jinyong')
  }, [_createElementVNode("span", {
    onClick: _cache[1] || (_cache[1] = $event => $options.to_dao())
  }, "CONFIRM")], 2), _createElementVNode("div", _hoisted_18, [_createElementVNode("span", {
    onClick: _cache[2] || (_cache[2] = $event => $options.back()),
    style: {
      "padding": "0.3rem 1rem",
      "background": "linear-gradient(180deg, #315FA1 0%, #1D9B9B 100%)"
    }
  }, "RETURN")])])]);
}